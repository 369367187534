import { moonsOSIncidences } from '../../utils/axios'
import alert from '../../components/alert/Alert.vue'

export default {
  name: 'Form',
  components: {
    'custom-alert': alert
  },
  /**
   * VUE STATE
   * @returns {Object} initial state
   */
  data() {
    return {
      disabledButtons: false,
      ticketId: this.$route.query.ticketId,
      currentScreen: 'form-screen'
    }
  },
  /**
   * VUE STATE
   * @returns {Object} initial state
  */
  mounted() {
    if (!this.ticketId) {
      this.$refs.customAlert.showAlert(
        'No se encontraro los datos necesarios para realizar el cuestionario por favor inténtalo de nuevo'
      )
      this.currentScreen = 'error-screen'
    }

    this.verifyTicket()
  },
  methods: {
    /**
     * handleSumbit
     * @param {String} response the user response (true/false)
     * @returns {Void} .-
     */
    async handleSumbit(response) {
      this.disabledButtons = true
      try {

        const { status } = await moonsOSIncidences.post('/close-ticket', {
          success: response,
          ticketId: this.ticketId
        })

        if (status !== 200) {
          this.$refs.customAlert.showAlert('Algo salió mal, por favor inténtalo de nuevo', 'error')
        }

      } catch (error) {
        this.$refs.customAlert.showAlert('Algo salió mal, por favor inténtalo más tarde', 'error')
        this.disabledButtons = false
        return
      }
      
      this.currentScreen = response ? 'success-screen' : 'no-success-screen'
    },
    /**
     * verifyTicket
     * @returns {Void} .-
     */
     async verifyTicket() {
      this.disabledButtons = true
      try {
        const { status, data } = await moonsOSIncidences.get('/ticket', {
          params: {
            objectId: this.ticketId
          }
        })

        if (status !== 200) {
          this.$refs.customAlert.showAlert('Algo salió mal, por favor inténtalo de nuevo')
          this.currentScreen = 'error-screen'
        }

        if (data.status !== 'Esperando confirmación'){
          this.$refs.customAlert.showAlert('Esta incidencia no es válida por el momento')
          this.currentScreen = 'error-screen'
        }

      } catch (error) {
        this.$refs.customAlert.showAlert('Algunos valores no están correctos, por favor vuelve abrir la liga desde el correo')
        this.currentScreen = 'error-screen'
      }
      this.disabledButtons = false
    }
  }
}
