export default {
  /**
   * VUE STATE
   * @returns {Object} initial state
   */
  data() {
    return {
      visible: false,
      message: '',
      type: 'info'
    }
  },

  methods: {
    /**
     * showAlert
     * @param {String} message .-
     * @param {String} type .-
     * @returns {Void} component destruction
     */
    showAlert(message, type = 'info') {
      this.message = message
      this.type = type
      this.visible = true

      setTimeout(() => {
        this.visible = false
      }, 3000)
    }
  }
}

